import React from "react"

export const HeadSEO = (titlePage?: string, metaDescription?: string | null, keyWords?: string | null) => {
  return (
    <>
      <meta name="theme-color" content="#00615c" />
      <meta name="msapplication-navbutton-color" content="#00615c" />
      <meta name="apple-mobile-web-app-status-bar-style" content="#00615c" />
      <meta name="google-site-verification" content="PWMJvupI2I9kODQW3v6MMXNGSjSaBIY8HuFr_8CwMAw" />
      <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests" />
      <title>
        {titlePage ? titlePage : "Verde Campo | Saúde e inovação na sua mesa"}
      </title>
      {metaDescription && <>
        <meta name="description" content={metaDescription} />
      </>}
    </>
  )
}
