import {
  Badge,
  Box,
  Flex,
  Image,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react"
import { HeadFC, Link } from "gatsby"
import moment from "moment"
import "moment/locale/pt-br"
import React from "react"
import { HeadSEO } from "../components/head/"
import { IPost } from "../dtos/IPosts.dto"
import { useThemePosts } from "../hooks/useThemePosts"
import { capitalizeFirstLetter } from "../utils/capitalizeFirstLetter"

type BlogPageProps = {
  pageContext: {
    posts: IPost[]
  }
}

const BlogPage = ({ pageContext }: BlogPageProps) => {
  const { posts } = pageContext
  const { data } = useThemePosts()
  const [firstPost, setFirstPost] = React.useState<IPost | null | undefined>(
    null,
  )
  const [lastPosts, setLastPosts] = React.useState<IPost[]>([])
  const [theme, setTheme] = React.useState("Todos")

  React.useEffect(() => {
    if (posts) {
      setFirstPost(posts?.shift())
      setLastPosts(posts)
    }
  }, [posts])

  return (
    <div id="blog">
      <style>
        {data?.map(
          t =>
            `
          .` +
            t.name.toLowerCase() +
            `{
          background: ` +
            t.cor_personalizada +
            `
          }
          `,
        )}
      </style>
      <div className="container header">
        <div className="row">
          <div className="col-12 d-flex flex-column align-items-center py-5">
            <span className="h1 text-title text-center">Blog</span>
            <span
              className="h4 text-paragraph text-center"
              style={{
                textAlign: "center",
                maxWidth: "700px",
              }}
            >
              Dicas, inspiração e conhecimento para uma vida saudável. No nosso
              blog, você encontrará uma variedade de artigos sobre alimentação,
              bem-estar e estilo de vida
            </span>
          </div>
        </div>
      </div>

      <Flex
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="100%"
        py="1rem"
        px="1rem"
      >
        <div className="row last-receita-wrapper">
          <div className="ultima-receita">
            {firstPost && (
              <Link to={"/blog/" + firstPost?.slug}>
                <a href="javascript:void(0)" className="receita-item">
                  <img
                    src={firstPost?.capa_da_postagem?.guid}
                    alt="Receita bolo"
                  />
                  <div className="receita-title flex-column text-light">
                    <span className="h2 text-light">
                      {firstPost?.titulo_da_postagem}
                    </span>
                    <hr />
                    <span className="h6 text-light">
                      {moment(firstPost?.date).format("LL")}
                    </span>
                  </div>
                </a>
              </Link>
            )}
          </div>
        </div>
      </Flex>

      <div className="container my-5">
        <hr />
        <div className="row pb-5">
          <div className="receitas-categorias">
            <a
              href="javascript:void(0)"
              onClick={() => setTheme("Todos")}
              className={
                theme == "Todos" ? " categoria-item active " : " categoria-item"
              }
            >
              <span className="h6">Todos</span>
            </a>

            {data?.map(i => (
              <a
                href="javascript:void(0)"
                onClick={() => setTheme(i.name)}
                className={
                  theme == i.name ? " categoria-item active" : " categoria-item"
                }
              >
                <span className="h6">{i.name}</span>
              </a>
            ))}
          </div>
        </div>

        <Flex width="100%" className="posts-blog">
          {lastPosts.length > 0 && (
            <SimpleGrid columns={[1, null, 2]} gap={6} width="100%">
              {theme != "Todos"
                ? lastPosts
                    ?.filter(i => i.categoria == theme)
                    .map((post, index) => (
                      <Flex
                        width="100%"
                        height="100%"
                        bg="#DEE6E4"
                        borderRadius="10px"
                        maxW={{
                          base: "31rem",
                          md: "100%",
                        }}
                        maxH={{
                          base: "100%",
                          md: "16.875rem",
                        }}
                        padding={{
                          base: "0",
                          md: "1.5rem",
                        }}
                        flexDir={{
                          base: "column",
                          md: "row",
                        }}
                      >
                        <Image
                          src={post?.capa_da_postagem?.guid}
                          alt="Placeholder"
                          objectFit="cover"
                          width="100%"
                          className="post--img"
                          borderRadius="10px 10px 0 0"
                          maxW={{
                            base: "100%",
                            md: "13rem",
                          }}
                          maxH="19.8rem"
                        />

                        <Stack spacing={3} padding="0.5rem 2.5rem" width="100%">
                          <Box>
                            <Badge
                              padding="0.2rem 1rem"
                              borderRadius="10px"
                              color="#fff"
                              className={post.categoria.toLowerCase()}
                            >
                              {post.categoria}
                            </Badge>
                          </Box>
                          <Text
                            as="h2"
                            fontSize={{ base: "1.125rem", md: "1rem" }}
                            color="#00615C"
                            fontWeight="700"
                            lineHeight="1.4rem"
                          >
                            {post?.titulo_da_postagem}
                          </Text>
                          <Text
                            as="p"
                            margin="0"
                            width="100%"
                            maxW="20rem"
                            noOfLines={3}
                            fontSize="0.875rem"
                          >
                            {post?.resumo}
                          </Text>
                          <Link to={"/blog/" + post?.slug}>Ler mais</Link>
                        </Stack>
                      </Flex>
                    ))
                : lastPosts?.map((post, index) => (
                    <Flex
                      width="100%"
                      height="100%"
                      bg="#DEE6E4"
                      borderRadius="10px"
                      maxW={{
                        base: "31rem",
                        md: "100%",
                      }}
                      maxH={{
                        base: "100%",
                        md: "16.875rem",
                      }}
                      padding={{
                        base: "0",
                        md: "1.5rem",
                      }}
                      flexDir={{
                        base: "column",
                        md: "row",
                      }}
                    >
                      <Link to={"/blog/" + post?.slug}>
                        <Image
                          src={post?.capa_da_postagem?.guid}
                          alt="Placeholder"
                          objectFit="cover"
                          width="100%"
                          borderRadius="10px 10px 0 0"
                          maxW={{
                            base: "100%",
                            md: "13rem",
                          }}
                          maxH="19.8rem"
                        />
                      </Link>
                      <Stack spacing={3} padding="0.5rem 2.5rem" width="100%">
                        <Box>
                          <Badge
                            padding="0.2rem 1rem"
                            borderRadius="10px"
                            color="#fff"
                            className={post.categoria.toLowerCase()}
                          >
                            {post.categoria}
                          </Badge>
                        </Box>
                        <Text
                          as="h2"
                          fontSize={{ base: "1.125rem", md: "1rem" }}
                          color="#00615C"
                          fontWeight="700"
                          lineHeight="1.4rem"
                        >
                          {post?.titulo_da_postagem}
                        </Text>
                        <Text
                          as="p"
                          margin="0"
                          width="100%"
                          maxW="20rem"
                          noOfLines={3}
                          fontSize="0.875rem"
                        >
                          {post?.resumo}
                          
                        </Text>
                        <Link to={"/blog/" + post?.slug}>Ler mais</Link>
                      </Stack>
                    </Flex>
                  ))}
            </SimpleGrid>
          )}
        </Flex>
      </div>
    </div>
  )
}

export default BlogPage

export const Head: HeadFC = () =>
  HeadSEO(
    capitalizeFirstLetter(
      `Blog | Dicas sobre alimentação saudável e mais | Verde Campo`,
    ),
    "Tenha acesso a dicas e informações exclusivas sobre uma rotina de alimentação saudável e bem-estar no blog da Verde Campo. Clique aqui para ler nossos artigos!",
    "",
  )
