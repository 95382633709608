import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Icon,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react"
import { HeadFC, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io"
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react"
import { ProductCard } from "../components/product_card"
import { OursLines } from "../mocks/oursLine"

import { HeadSEO } from "../components/head/"
import { IProduct } from "../dtos/IProduct.dto"
import { capitalizeFirstLetter } from "../utils/capitalizeFirstLetter"
import { useProductsAndOrderByCategory } from "../hooks/useProduct"

type PageProps = {
  location: {
    state: {
      categoryId: string
      productId: string
    }
  }
  pageContext: {
    products: IProduct[]
  }
}


const OursLine: React.FC<PageProps> = ({ location, pageContext }) => {
  const { products } = pageContext

  const [show, setShow] = React.useState(false)
  const [textOurLines, setTextOurLines] = React.useState<string>("")
  const ourlinesRefs = React.useRef<HTMLDivElement>(null)

  const [dataOthers, setDataOthers] = React.useState<IProduct[] | undefined>([])
  const [dataHighlight, _] = React.useState<IProduct[]>(
    products.filter(product => product.categories.includes(68)),
  )

  const { data: dataHighlightOrdered } = useProductsAndOrderByCategory(products, "68")
  const [activeCategory, setActiveCategory] = React.useState<string>("11")
  const [firstProduct, setFirstProduct] = React.useState<string>("59")
  const swiperRef = React.useRef<SwiperRef>(null)
  const swiperRefOurLines = React.useRef<SwiperRef>(null)
  const swiperRefOthers = React.useRef<SwiperRef>(null)

  const { data: dataOthersOrdered } = useProductsAndOrderByCategory(products, activeCategory)

  const configSwiper = {
    spaceBetween: 10,
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      455: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 3.5,
        spaceBetween: 40,
      },
      1024: {
        slidesPerView: 3.2,
        spaceBetween: 50,
      },
    },
  }

  const configSwiperOthers = {
    spaceBetween: 10,
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      455: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 40,
      },
      890: {
        slidesPerView: 1.8,
        spaceBetween: 30,
      },
      1024: {
        slidesPerView: 2.2,
        spaceBetween: 30,
      },
      1280: {
        slidesPerView: 3.2,
        spaceBetween: 50,
      },
    },
  }

  const configSwiperOurLines = {
    spaceBetween: 10,
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      455: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 40,
      },
    },
  }

  const scrollToLine = (id: any) => {
    setActiveCategory(id)

    setTimeout(() => {
      ourlinesRefs.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      })
    }, 1400)
  }



  const nextSlide = (
    ref: React.MutableRefObject<SwiperRef | null> | undefined,
  ) => {
    if (ref?.current && ref?.current.swiper) {
      ref?.current?.swiper?.slideNext()
    }
  }

  const prevSlide = (
    ref: React.MutableRefObject<SwiperRef | null> | undefined,
  ) => {
    if (ref?.current && ref?.current.swiper) {
      ref?.current?.swiper?.slidePrev()
    }
  }

  const state: any = location.state



  React.useEffect(() => {

    const matching = dataOthersOrdered?.filter(item => item.categories.toString().includes(firstProduct)) || [];
    const nonMatching = dataOthersOrdered?.filter(item => !item.categories.toString().includes(firstProduct)) || []
    const orderedData = [...matching, ...nonMatching];

    setDataOthers(orderedData)


    setTextOurLines(
      () => {
        const text = OursLines.find(category => category.category_id === "11")
          ?.description
        return text ?? ""
      }
    )


  console.log(orderedData)
  }, [activeCategory, dataOthersOrdered, firstProduct])

  React.useEffect(() => {
    if (state?.categoryId) scrollToLine(state?.categoryId)
    if (state?.productId) setFirstProduct(state?.productId)
  }, [])

  return (
    <body>
      <div className="container header">
        <div className="row">
          <div className="col-12 d-flex flex-column align-items-center py-5">
            <span className="h1 text-title text-center">Produtos</span>
            <span
              className="h4 text-paragraph text-center"
              style={{ textAlign: "center", maxWidth: "600px" }}
            >
              Transforme sua alimentação com os produtos naturais e deliciosos
              da Verde Campo. Desfrute de uma experiência única, repleta de
              sabor e qualidade em cada escolha
            </span>
          </div>
        </div>
      </div>

      <Box background="#F2F4F3" py="5rem" width="100%">
        <Box px={{ base: "1rem", md: "9rem" }}>
          <div className="row">
            <div className="col-12">
              <div className="h2 text-title-litle">Destaques</div>
              <hr
                className="my-5"
                style={{ backgroundColor: "#ffffff80", height: "1px" }}
              />
            </div>
          </div>

          {dataHighlightOrdered && (
            <Box position="relative">
              <Flex
                as="button"
                alignItems="center"
                justifyContent="center"
                borderRadius="50%"
                opacity="0.8"
                background="#fff"
                padding="0.5rem"
                onClick={() => prevSlide(swiperRef)}
                cursor="pointer"
                position="absolute"
                left="0"
                top="50%"
                transform="translateY(-50%)"
              >
                <Icon as={IoIosArrowBack} color="#008C15" fontSize="2rem" />
              </Flex>
              <Box px={{ base: "1rem", md: "4rem" }}>
                <Swiper {...configSwiper} ref={swiperRef}>
                  {dataHighlightOrdered?.filter((el) => el.category.includes("highlight")).map(product => (
                    <SwiperSlide key={product.id}>
                      <Box padding="1rem">
                        <ProductCard
                          activeRibbon
                          ribbonText="Novidade"
                          product={product}
                        />
                      </Box>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Box>
              <Flex
                as="button"
                alignItems="center"
                justifyContent="center"
                borderRadius="50%"
                opacity="0.8"
                background="#fff"
                padding="0.5rem"
                onClick={() => nextSlide(swiperRef)}
                cursor="pointer"
                position="absolute"
                right="0"
                top="50%"
                transform="translateY(-50%)"
              >
                <Icon as={IoIosArrowForward} color="#008C15" fontSize="2rem" />
              </Flex>
            </Box>
          )}
        </Box>
      </Box>

      <div className="container mb-5">
        <div className="row mt-5">
          <div className="col-12">
            <div className="h2 text-title-litle">Nossas Linhas</div>
            <hr
              className="my-5"
              style={{ backgroundColor: "#ffffff80", height: "1px" }}
            />
          </div>
        </div>
        {OursLines && (
          <>
            <Box position="relative">
              <Flex
                as="button"
                alignItems="center"
                justifyContent="center"
                borderRadius="50%"
                opacity="0.8"
                background="#fff"
                padding="0.5rem"
                onClick={() => prevSlide(swiperRefOurLines)}
                cursor="pointer"
                position="absolute"
                left="0"
                top="50%"
                transform="translateY(-50%)"
              >
                <Icon as={IoIosArrowBack} color="#008C15" fontSize="2rem" />
              </Flex>
              <Box px={{ base: "1rem", md: "4rem" }}>
                <Swiper {...configSwiperOurLines} ref={swiperRefOurLines}>
                  {OursLines?.map(product => (
                    <SwiperSlide key={product.id}>
                      <Box padding="1rem" h="100%">
                        <Stack
                          maxW="35rem"
                          width="100%"
                          height="100%"
                          alignItems="center"
                          justifyContent="space-between"
                          spacing={"1.98rem"}
                        >
                          <Box>
                            <Text
                              fontSize="2rem"
                              fontWeight="700"
                              lineHeight="125%"
                              textAlign="center"
                              color="#044F32"
                            >
                              {product?.name.replace("lacfree", "LACFREE") ??
                                "Nome do Produto"}
                            </Text>
                            <HStack
                              alignItems="center"
                              justifyContent="space-around"
                              width="100%"
                              maxW="30rem"
                            >
                              {product?.claims.map(imagesUrl => (
                                <Image
                                  src={`https://admin.verdecampo.com.br/claims-images/${imagesUrl}`}
                                  alt="Claim Verde Campo"
                                  className="img-claim"
                                />
                              ))}
                            </HStack>
                          </Box>

                          <Image
                            src={
                              product?.image.replace("/site2018/", "/") ??
                              "https://via.placeholder.com/550"
                            }
                            h="23rem"
                            w="23rem"
                            objectFit={"contain"}
                            alt="Product Image"
                            borderRadius="1rem"
                          />
                          <Button
                            background="#F2F4F3"
                            borderRadius="12.5rem"
                            variant="solid"
                            color="#025F1D"
                            fontWeight="800"
                            fontSize="1.125rem"
                            lineHeight="125%"
                            padding="2rem 2rem"
                            _hover={{ opacity: 0.9 }}
                            onClick={() => scrollToLine(product?.category_id)}
                          >
                            Ver mais detalhes{" "}
                          </Button>
                        </Stack>
                        <StaticImage
                          src="../images/fundoproduto.png"
                          alt="Place holder"
                          style={{
                            position: "absolute",
                            bottom: 0,
                            zIndex: "-1",
                          }}
                        />
                      </Box>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Box>
              <Flex
                as="button"
                alignItems="center"
                justifyContent="center"
                borderRadius="50%"
                opacity="0.8"
                background="#fff"
                padding="0.5rem"
                onClick={() => nextSlide(swiperRefOurLines)}
                cursor="pointer"
                position="absolute"
                right="0"
                top="50%"
                transform="translateY(-50%)"
              >
                <Icon as={IoIosArrowForward} color="#008C15" fontSize="2rem" />
              </Flex>
            </Box>
          </>
        )}

        {/* {isLoadingOurLines && (
          <Flex justifyContent="space-between" alignItems="center">
            <ProductCardBigSkeleton quantity={isMobile ? 1 : 2} />
          </Flex>
        )} */}
      </div>

      <Box ref={ourlinesRefs} background="#F2F4F3" py="5rem" width="100%">
        <Flex
          px={{ base: "1rem", md: "9rem" }}
          flexDirection="column"
          gap="2rem"
        >
          <Flex justifyContent="center" alignItems="center" width="100%">
            <Flex
              justifyContent="space-between"
              alignItems="center"
              maxW="55rem"
              width="100%"
              className="btn-aba-linhas"
              gap="1rem"
            >
              {OursLines.map(category => (
                <Button
                  key={category.id}
                  onClick={() => {
                    setActiveCategory(category.category_id)
                    scrollToLine(category?.category_id)
                  }}
                  variant="outline"
                  colorScheme="green"
                  fontSize={{
                    base: "0.6rem",
                    sm: "0.7rem",
                    md: "0.8rem",
                    lg: "1rem",
                    xl: "1.25rem",
                  }}
                  fontWeight="bold"
                  borderRadius="1.5rem"
                  background={
                    activeCategory === category.category_id ? "#FF6A14" : ""
                  }
                  color={
                    activeCategory === category.category_id ? "#fff" : "#00615C"
                  }
                  _hover={{
                    background: "#FF6A14",
                    color: "#ffffff",
                    opacity: 0.7,
                  }}
                >
                  {category.name}
                </Button>
              ))}
            </Flex>
          </Flex>
          <Divider height="0.0625rem" background="#ADBDB9" />

          <Flex width="100%" alignItems="center" justifyContent="center">
            <Text
              textAlign="center"
              fontSize="1.125rem"
              fontWeight="350"
              lineHeight="1.8rem"
              fontFamily="P22 Mackinac Pro"
              color="#495754"
              transition="all 0.2s ease-in-out"
              className="description-linha"
            >
              {textOurLines?.length > 80 && !show
                ? `${textOurLines.substring(0, 80)}...`
                : textOurLines}
              {textOurLines?.length > 80 && (
                <Text
                  as="span"
                  color="#01615c"
                  fontWeight="700"
                  onClick={() => setShow(show => !show)}
                  cursor="pointer"
                  ml="0.5rem"
                  fontSize="1rem"
                >
                  {show ? "Ver menos" : "Ver mais"}
                </Text>
              )}
            </Text>
          </Flex>

          <Box
            justifyContent="space-between"
            alignItems="center"
            position="relative"
          >
            <Flex
              as="button"
              alignItems="center"
              justifyContent="center"
              borderRadius="50%"
              opacity="0.8"
              background="#fff"
              padding="0.5rem"
              onClick={() => prevSlide(swiperRefOthers)}
              cursor="pointer"
              position="absolute"
              left="0"
              top="50%"
              transform="translateY(-50%)"
            >
              <Icon as={IoIosArrowBack} color="#008C15" fontSize="2rem" />
            </Flex>
            <Box px={{ base: "1rem", md: "4rem" }}>
              <Swiper {...configSwiperOthers} ref={swiperRefOthers}>
                {dataOthers?.filter(
                  (v, i, a) => a.findIndex(t => t.oldId === v.oldId) === i,
                ).map(product => (
                  <SwiperSlide key={product.id}>
                    <Box padding="1rem">
                      <ProductCard
                        activeRibbon={product.legenda_do_vdeo === "novo" ? true : false}
                        ribbonText={product.legenda_do_vdeo === "novo" ? "Novidade" : ""}
                        product={product} />
                    </Box>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Box>

            <Flex
              as="button"
              alignItems="center"
              justifyContent="center"
              borderRadius="50%"
              opacity="0.8"
              background="#fff"
              padding="0.5rem"
              onClick={() => nextSlide(swiperRefOthers)}
              cursor="pointer"
              position="absolute"
              right="0"
              top="50%"
              transform="translateY(-50%)"
            >
              <Icon as={IoIosArrowForward} color="#008C15" fontSize="2rem" />
            </Flex>
          </Box>
        </Flex>
      </Box>

      {/* <div className="p-0 compre-natural-whey">
        <div className="row mx-0">
          <div className="col-md-5 p-0">
            <StaticImage
              className="p-0 m-0"
              src="../images/compre-natural-whey.png"
              alt="Natural Whey"
              quality={95}
              formats={["png"]}
            />
          </div>
          <div className="col-md-7 p-0">
            <div
              className="d-flex flex-column h-100 justify-content-center p-3 p-md-5"
              style={{ maxWidth: "700px" }}
            >
              <span className="h1">COMPRE AGORA O SEU SHAKE NATURAL WHEY</span>
              <span className="paragraph">
                Venha conferir a loja Verde Campo no Mercado Livre. Agora ficou
                muito mais fácil comprar toda a linha de Shake Natural Whey, nos
                sabores chocolate e baunilha. E o melhor de tudo: entrega para
                todo o Brasil e com preço especial!
              </span>
              <div className="d-flex flex-row mt-3">
                <a
                  href="https://loja.mercadolivre.com.br/verde-campo"
                  target="_blank"
                  className="btn btn-card-line orange px-5 px-md-5 mr-2"
                >
                  Ver na loja
                </a>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="container">
        <div className="card-reclamacao py-5">
          <div className="row">
            <div className="col-xl-6 col-10 text-center mx-auto">
              <span className="h2 text-center mb-1">
                Ainda restou alguma dúvida?
              </span>
              <p className="lead mt-0">
                Clique em um dos botões abaixo e nos conte melhor sobre o seu
                problema ou dúvida.
              </p>
              <div className="d-flex flex-column align-items-center">
                <Link to="/faq" className="btn btn-duvida my-1">
                  Tenho uma Dúvida
                </Link>
                <Link to="/faq" className="btn btn-reclamacao my-1">
                  Fazer reclamação
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  )
}

export default OursLine

export const Head: HeadFC = () =>
  HeadSEO(
    capitalizeFirstLetter(
      `Conheça Nossas Linhas | Produtos Saudáveis - Verde Campo`,
    ),
    "Explore as diversas linhas de produtos da Verde Campo. Descubra opções saudáveis e saborosas para sua rotina. Veja agora!",
    "",
  )
